
import CartItemPrint from "./cartItemPrint";

const CartItem = () => {

  return (
   
    <CartItemPrint></CartItemPrint>
  );
};
export default CartItem;
